<template> 
<div @click="onClickAction()" class="pa-5">
  <hb-data-table
      :headers="isPropertySelected ? REVERSAL.PROPERTY_HEADERS : REVERSAL.HEADERS"
      :items="items"
      @click:row="editItem"
      :loading="loading"
  >
      <template v-slot:item.type="{ item }">
          <span class="border-bottom-dashed">{{ REVERSAL.LABELS[item.type] }}</span>
      </template>
      <template v-slot:item.product_id="{ item }">
          {{ getReversalFee(item) }}
      </template>
      <template v-slot:item.document_id="{ item }">
          <span class="border-bottom-dashed">{{ getReversalDocument(item) }}</span>
      </template>
      <template v-slot:item.email="{ item }">
          <span v-if="item.email.active">
                <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
           </span>
           <span v-else>
                <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
           </span>
      </template>
  </hb-data-table>
  <edit-reversal v-model="showEdit" v-if="showEdit" :reversals="items" :types=REVERSAL.TYPES :facilityID="facilityID" :isPropertySelected="isPropertySelected" :fees="fees" :documents="reversalDocuments" :selected="selected" @close="closeWindow()" @refetch="fetchReversals" />
  <payments-notification-modal
      v-model="notificationModal"
      notificationType="manage_settings_bill"
    >
    </payments-notification-modal>
</div>
</template>

<script type="text/babel">
import FeeDocument from './FeeDocument';
import api from '../../../assets/api.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters } from 'vuex';
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import Modal from '../../assets/Modal.vue';
import Loader from '../../assets/CircleSpinner.vue';
import EditReversal from './EditReversal.vue';
import { REVERSAL } from "./Constants.js";

export default {
  name: "Reversals",
  props: ['level', 'facilityID'],
  data() {
    return {
      CATEGORY_TYPE: 'delinquency',
      isEditReversals: false,
      notificationModal: false,
      products: [],
      showEdit: false,
      selectedId:'',
      selected:{},
      reversalDocuments: [],
      items:[],
      Headers:[],
      loading: true
    };
  },
  mixins: [ notificationMixin ],
  components: {
    FeeDocument,
    PaymentsNotificationModal,
    Modal,
    Loader,
    EditReversal
  },
  async created() {
    this.REVERSAL = REVERSAL;
    await this.fetchReversals();
    await this.fetchProducts();
    await this.fetchDocuments();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission'
    }),
    fees(){
      return this.products.filter(p => p.default_type === 'late' && p.category_type === this.CATEGORY_TYPE)
    },
    isPropertySelected() {
      return this.level === 'Property' && this.facilityID ? true : false;
    },
    hasBilligEditPermission(){
      return this.hasPermission("manage_settings_bill");
    },
    subheader() {
      return this.REVERSAL.SUBHEAD;
    },
  },
  methods: {
    getReversalFee(reversal) {
      return this.fees.find(f => f.id === reversal.product_id) ? this.fees.find(f => f.id === reversal.product_id).name : 'No Fee';
    },
    getReversalDocument(reversal) {
      return this.reversalDocuments.find(d => d.id === reversal.document_id) ? this.reversalDocuments.find(d => d.id === reversal.document_id).name : 'No Document';
    },
    onClickAction(){
      if (!this.hasBilligEditPermission) {
        this.notificationModal = true;
      }
    },
    async fetchReversals() {
      const revTypes = this.REVERSAL.TYPES;
      let path = this.isPropertySelected ? api.PROPERTIES + this.facilityID + '/reversal' : api.BILLING + 'reversals';
      
      await api.get(this, path).then(r => {
        this.items = [];
        for(let i = 0 ; i < revTypes.length; i++){
          if(this.isPropertySelected){
            this.items.push({
              type:r.reversals[revTypes[i]]?.type ? r.reversals[revTypes[i]]?.type : 'unknown',
              product_id:r.reversals[revTypes[i]]?.product_id ? r.reversals[revTypes[i]]?.product_id : '',
              document_id:r.reversals[revTypes[i]]?.document_id ? r.reversals[revTypes[i]]?.document_id : '',
              property_id:r.reversals[revTypes[i]]?.property_id ? r.reversals[revTypes[i]]?.property_id : null,
            }); 
          }else{
            this.items.push({
              type:r.reversals[revTypes[i]]?.type ? r.reversals[revTypes[i]]?.type : 'unknown',
              product_id:r.reversals[revTypes[i]]?.product_id ? r.reversals[revTypes[i]]?.product_id : '',
              document_id:r.reversals[revTypes[i]]?.document_id ? r.reversals[revTypes[i]]?.document_id : '',
              email:{
                active: r.reversals[revTypes[i]]?.email?.active ? r.reversals[revTypes[i]]?.email?.active : false,
                subject:r.reversals[revTypes[i]]?.email?.subject ? r.reversals[revTypes[i]]?.email?.subject : '',
                message:r.reversals[revTypes[i]]?.email?.message ? r.reversals[revTypes[i]]?.email?.message : ''
              },
              property_id:r.reversals[revTypes[i]]?.property_id ? r.reversals[revTypes[i]]?.property_id : null,
            }); 
          }
        } 
      }).catch(err => {
          this.showMessageNotification({description: err});
      });

      this.isEditReversals = this.hasBilligEditPermission;
    },
    async fetchProducts(){
      let path = this.isPropertySelected ? api.PROPERTIES + this.facilityID + `/products?type=late&category_type=${this.CATEGORY_TYPE}` : api.PRODUCTS
      await api.get(this, path, this.level === 'Corporate' && {
          search: 'late'
      }).then(r => {
          this.products = r.products;
      }).catch(err => {
          this.showMessageNotification({description: err});
      });
    },
    async fetchDocuments() {
      try {
        let path = (this.isPropertySelected) ? `&property_ids[]=${this.facilityID}` : '';
        this.reversalDocuments = [];
        let limit = 200, offset = 1;
        let res = await api.get(this, api.DOCUMENT + `?type[]=reversal&limit=${limit}&offset=${offset}` + path);
        this.reversalDocuments = res.documents ? res.documents : [];
      } catch (err) {
        //handle notification
        this.showMessageNotification({description: err});
      }
    },
    editItem(c){
      if(!this.loading && this.hasBilligEditPermission){
        this.selected = c;
        this.selectedId = c?.id;
        this.showEdit = true;
      }
    },
    closeWindow(){
      this.selected = {};
      this.selectedId = '';
      this.showEdit = false;
    },
    async downloadGuide(name){
      let res = await api.get(this, api.DOCUMENT + 'guide/' + name);
      var buffer = Buffer.from(res.data);
      var blob = new Blob([buffer], {type: res.content_type });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = res.file_name;
      link.click();
    },
  },
  watch: {
    async "facilityID"() {
      this.loading = true;
      await this.fetchDocuments();
      await this.fetchReversals();
      await this.fetchProducts();
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.border-bottom-dashed{
  border-bottom: 1px dashed;
}
</style>
